import React from "react"

import MarketoForm from "../components/MarketoForm";
import Hero from "../components/Hero";
import Layout from "../components/Layout"
import ResponsiveContainer from "../components/ResponsiveContainer";
import SEO from "../components/Seo"

export const cutomerServiceUrl = `/customer-service/`;

const cutomerService = () => {
  const hero = (
    <Hero
      title="ServiceLink Customer Service"
      showFindOutMore={false}
    />
  );
  return (
    <Layout hero={hero} showContactForm={false}>
      <SEO title="ServiceLink Customer Service" />
      <ResponsiveContainer width="thin" spacingTop="half">
       
      </ResponsiveContainer>
    </Layout>
  );
}

export default cutomerService;
